const navArray = [
    {
        id: 1,
        name: "home",
        link: "/"
    },
    {
        id: 2,
        name: "experience",
        link: "experience"
    },
    {
        id: 3,
        name: "projects",
        link: "projects"
    },
    {
        id: 4,
        name:  "interests",
        link: "interests"
    }
]

export default navArray