import { Link } from 'react-router-dom'
 
function ErrorPage() {
    return (
        <div>
            
            Something went wrong<br /><br />

            <Link to="/">Return Home</Link>


        </div>
    )
}

export default ErrorPage